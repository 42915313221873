@charset "UTF-8";
/*
1.
$light-앨리어스네임 : 컬러값
$dark-앨리어스네임 : 컬러값
쌍으로 컬러별 변수를 정의한다. #hex의 경우 끝에 -hex 를 붙힌다.

2. $light-theme, $dark-theme 리스트에 1.을 추가한다
*/
/* 2. Gray, 3. Text, Button(=Gray) --> RGBA, RGB to Hex */
/* 1. BG,  4. UI Text  --> only RGB to Hex  */
/* light-mode, dark-mode color list  */
/* color mixin import */
:root {
  --ncui-gray002: rgba(34, 34, 34, 0.02);
  --ncui-gray002-hex: #FBFBFB;
  --ncui-gray004: rgba(34, 34, 34, 0.04);
  --ncui-gray004-hex: #F6F6F6;
  --ncui-gray008: rgba(34, 34, 34, 0.08);
  --ncui-gray008-hex: #EEEEEE;
  --ncui-gray010: rgba(34, 34, 34, 0.1);
  --ncui-gray010-hex: #E8E8E8;
  --ncui-gray012: rgba(34, 34, 34, 0.12);
  --ncui-gray012-hex: #E4E4E4;
  --ncui-gray016: rgba(34, 34, 34, 0.16);
  --ncui-gray016-hex: #DBDBDB;
  --ncui-gray020: rgba(34, 34, 34, 0.2);
  --ncui-gray020-hex: #D3D3D3;
  --ncui-gray040: rgba(34, 34, 34, 0.4);
  --ncui-gray040-hex: #A7A7A7;
  --ncui-black045: rgba(0, 0, 0, 0.45);
  --ncui-black068: rgba(0, 0, 0, 0.68);
  --ncui-black093: rgba(0, 0, 0, 0.93);
  --ncui-black100: #000000;
  --ncui-white100: #FFFFFF;
  --ncui-ui-white: #FFFFFF;
  --ncui-ui-black: #000000;
  --ncui-bg: #FFFFFF;
  --ncui-error: #DC4141;
  --ncui-bookmark: #FFD042;
  --ncui-sucess: #1EB22E;
  --ncui-button-normal: #333333;
  --ncui-button-hover: #000000;
  --ncui-linebutton-noraml: rgba(34, 34, 34, 0.12);
}

html.dark-mode {
  --ncui-gray002: rgba(255, 255, 255, 0.02);
  --ncui-gray002-hex: #1B1B1B;
  --ncui-gray004: rgba(255, 255, 255, 0.04);
  --ncui-gray004-hex: #202020;
  --ncui-gray008: rgba(255, 255, 255, 0.08);
  --ncui-gray008-hex: #292929;
  --ncui-gray010: rgba(255, 255, 255, 0.1);
  --ncui-gray010-hex: #2E2E2E;
  --ncui-gray012: rgba(255, 255, 255, 0.12);
  --ncui-gray012-hex: #333333;
  --ncui-gray016: rgba(255, 255, 255, 0.16);
  --ncui-gray016-hex: #3C3C3C;
  --ncui-gray020: rgba(255, 255, 255, 0.2);
  --ncui-gray020-hex: #454545;
  --ncui-gray040: rgba(255, 255, 255, 0.4);
  --ncui-gray040-hex: #A7A7A7;
  --ncui-black045: rgba(255, 255, 255, 0.38);
  --ncui-black068: rgba(255, 255, 255, 0.6);
  --ncui-black093: rgba(255, 255, 255, 0.83);
  --ncui-black100: #FFFFFF;
  --ncui-white100: #000000;
  --ncui-ui-white: #FFFFFF;
  --ncui-ui-black: #000000;
  --ncui-bg: #171717;
  --ncui-error: #B94444;
  --ncui-bookmark: #CCA634;
  --ncui-sucess: #2F8738;
  --ncui-button-normal: #D7D7D7;
  --ncui-button-hover: #FFFFFF;
  --ncui-linebutton-noraml: rgba(255, 255, 255, 0.12);
}

:root {
  --ncui-point: #1f3263;
  --ncui-point040: rgba(31, 50, 99, 0.4);
  --ncui-point-hover: #1f3263;
  --ncui-point-text: #1f3263;
  --ncui-point-text080: rgba(31, 50, 99, 0.8);
}

html.dark-mode {
  --ncui-point: #2b3f73;
  --ncui-point040: rgba(43, 63, 115, 0.4);
  --ncui-point-hover: #2b3f73;
  --ncui-point-text: #5672ba;
  --ncui-point-text080: rgba(86, 114, 186, 0.55);
}

html[data-service=aion] {
  --ncui-point: #5CA3C3;
  --ncui-point040: rgba(92, 163, 195, 0.4);
  --ncui-point-hover: #16717C;
  --ncui-point-text: #5CA3C3;
  --ncui-point-text080: rgba(92, 163, 195, 0.8);
  --ncui-point-download1: #97BCD0;
  --ncui-point-download2: #C1C8CD;
  --ncui-point-download3: #92A4AE;
  --ncui-point-download4: #A2C8D1;
  --ncui-point-download5: #A8C3D3;
}

html[data-service=aion].dark-mode {
  --ncui-point: #215C76;
  --ncui-point040: rgba(33, 92, 118, 0.4);
  --ncui-point-hover: #1E536A;
  --ncui-point-text: #2F80A5;
  --ncui-point-text080: rgba(47, 128, 165, 0.55);
  --ncui-point-download1: #576973;
  --ncui-point-download2: #6C6F72;
  --ncui-point-download3: #545D62;
  --ncui-point-download4: #5C6F74;
  --ncui-point-download5: #5C6F74;
}

html[data-service=bns] {
  --ncui-point: #3B429F;
  --ncui-point040: rgba(59, 66, 159, 0.4);
  --ncui-point-hover: #353C8F;
  --ncui-point-text: #3B429F;
  --ncui-point-text080: rgba(59, 66, 159, 0.8);
  --ncui-point-download1: #917FA9;
  --ncui-point-download2: #AEA4B0;
  --ncui-point-download3: #C2AAA5;
  --ncui-point-download4: #7F84AC;
}

html[data-service=bns].dark-mode {
  --ncui-point: #323880;
  --ncui-point040: rgba(50, 56, 128, 0.4);
  --ncui-point-hover: #2D3373;
  --ncui-point-text: #7981e1;
  --ncui-point-text080: rgba(121, 129, 225, 0.55);
  --ncui-point-download1: #544B60;
  --ncui-point-download2: #625D63;
  --ncui-point-download3: #6C605E;
  --ncui-point-download4: #4B4E61;
}

html[data-service=bns2] {
  --ncui-point: #B54838;
  --ncui-point040: rgba(181, 72, 56, 0.4);
  --ncui-point-hover: #A34133;
  --ncui-point-text: #B54838;
  --ncui-point-text080: rgba(181, 72, 56, 0.8);
}

html[data-service=bns2].dark-mode {
  --ncui-point: #9D3F31;
  --ncui-point040: rgba(157, 63, 49, 0.4);
  --ncui-point-hover: #8E392C;
  --ncui-point-text: #BE5141;
  --ncui-point-text080: rgba(190, 81, 65, 0.55);
}

html[data-service=h2] {
  --ncui-point: #5FB8E3;
  --ncui-point040: rgba(95, 184, 227, 0.4);
  --ncui-point-hover: #56A6CD;
  --ncui-point-text: #5FB8E3;
  --ncui-point-text080: rgba(95, 184, 227, 0.8);
}

html[data-service=h2].dark-mode {
  --ncui-point: #388DB6;
  --ncui-point040: rgba(56, 141, 182, 0.4);
  --ncui-point-hover: #337FA4;
  --ncui-point-text: #54BCEF;
  --ncui-point-text080: rgba(84, 188, 239, 0.55);
}

html[data-service=h3] {
  --ncui-point: #006FDE;
  --ncui-point040: rgba(0, 111, 222, 0.4);
  --ncui-point-hover: #0064C8;
  --ncui-point-text: #006FDE;
  --ncui-point-text080: rgba(0, 111, 222, 0.8);
}

html[data-service=h3].dark-mode {
  --ncui-point: #11579C;
  --ncui-point040: rgba(17, 87, 156, 0.4);
  --ncui-point-hover: #0F4E8D;
  --ncui-point-text: #459DF5;
  --ncui-point-text080: rgba(69, 157, 245, 0.55);
}

html[data-service=lineage] {
  --ncui-point: #C38B61;
  --ncui-point040: rgba(195, 139, 97, 0.4);
  --ncui-point-hover: #B07D57;
  --ncui-point-text: #C38B61;
  --ncui-point-text080: rgba(195, 139, 97, 0.8);
  --ncui-point-download1: #C69C7C;
  --ncui-point-download2: #9C8B7F;
  --ncui-point-download3: #B5BDCA;
}

html[data-service=lineage].dark-mode {
  --ncui-point: #A16E47;
  --ncui-point040: rgba(161, 110, 71, 0.4);
  --ncui-point-hover: #916340;
  --ncui-point-text: #EAAF83;
  --ncui-point-text080: rgba(234, 175, 131, 0.55);
  --ncui-point-download1: #6E5949;
  --ncui-point-download2: #59514B;
  --ncui-point-download3: #666A70;
}

html[data-service=lineage2] {
  --ncui-point: #187D89;
  --ncui-point040: rgba(24, 125, 137, 0.4);
  --ncui-point-hover: #16717C;
  --ncui-point-text: #187D89;
  --ncui-point-text080: rgba(24, 125, 137, 0.8);
  --ncui-point-download1: #7FAC96;
  --ncui-point-download2: #8EA97F;
  --ncui-point-download3: #A4AEB0;
  --ncui-point-download4: #A5BFC2;
}

html[data-service=lineage2].dark-mode {
  --ncui-point: #0B6F7B;
  --ncui-point040: rgba(11, 111, 123, 0.4);
  --ncui-point-hover: #0A646F;
  --ncui-point-text: #2193A1;
  --ncui-point-text080: rgba(33, 147, 161, 0.55);
  --ncui-point-download1: #4B6157;
  --ncui-point-download2: #52604B;
  --ncui-point-download3: #5D6263;
  --ncui-point-download4: #5E6B6C;
}

html[data-service^=lineage2m] {
  --ncui-point: #CF631F;
  --ncui-point040: rgba(207, 99, 31, 0.4);
  --ncui-point-hover: #BB591C;
  --ncui-point-text: #CF631F;
  --ncui-point-text080: rgba(207, 99, 31, 0.8);
  --ncui-footer-bg: #241F1C;
  --ncui-footer-select-border: #45413E;
  --ncui-footer-select-bg: #35312E;
  --ncui-point-download1: #e3bca3;
  --ncui-point-download2: #d2a283;
}

html[data-service^=lineage2m].dark-mode {
  --ncui-point: #944A1B;
  --ncui-point040: rgba(148, 74, 27, 0.4);
  --ncui-point-hover: #854318;
  --ncui-point-text: #FF8940;
  --ncui-footer-bg: #241F1C;
  --ncui-footer-select-border: #45413E;
  --ncui-footer-select-bg: #35312E;
  --ncui-point-text080: rgba(255, 137, 64, 0.55);
  --ncui-point-download1: #806858;
  --ncui-point-download2: #5f4433;
}

html[data-service=lineagem] {
  --ncui-point: #A7694F;
  --ncui-point040: rgba(167, 105, 79, 0.4);
  --ncui-point-hover: #975F47;
  --ncui-point-text: #A7694F;
  --ncui-point-text080: rgba(167, 105, 79, 0.8);
}

html[data-service=lineagem].dark-mode {
  --ncui-point: #82513D;
  --ncui-point040: rgba(130, 81, 61, 0.4);
  --ncui-point-hover: #754937;
  --ncui-point-text: #C58466;
  --ncui-point-text080: rgba(197, 132, 102, 0.55);
}

html[data-service=lineagew] {
  --ncui-point: #B09067;
  --ncui-point040: rgba(176, 144, 103, 0.4);
  --ncui-point-hover: #9F825D;
  --ncui-point-text: #B09067;
  --ncui-point-text080: rgba(176, 144, 103, 0.8);
  --ncui-footer-bg: #241F1C;
  --ncui-footer-select-border: #45413E;
  --ncui-footer-select-bg: #35312E;
}

html[data-service=lineagew].dark-mode {
  --ncui-point: #957C5C;
  --ncui-point040: rgba(149, 124, 92, 0.4);
  --ncui-point-hover: #867053;
  --ncui-point-text: #AE9473;
  --ncui-point-text080: rgba(174, 148, 115, 0.55);
  --ncui-footer-bg: #241F1C;
  --ncui-footer-select-border: #45413E;
  --ncui-footer-select-bg: #35312E;
}

html[data-service=tricksterm] {
  --ncui-point: #7642EF;
  --ncui-point040: rgba(118, 66, 239, 0.4);
  --ncui-point-hover: #6A3CD8;
  --ncui-point-text: #7642EF;
  --ncui-point-text080: rgba(118, 66, 239, 0.8);
}

html[data-service=tricksterm].dark-mode {
  --ncui-point: #422E85;
  --ncui-point040: rgba(66, 46, 133, 0.4);
  --ncui-point-hover: #3C2978;
  --ncui-point-text: #A185FF;
  --ncui-point-text080: rgba(161, 133, 255, 0.55);
}

html[data-service=tl] {
  --ncui-point: #9F64C5;
  --ncui-point040: rgba(159, 100, 197, 0.4);
  --ncui-point-hover: #48275E;
  --ncui-point-text: #9F64C5;
  --ncui-point-text080: rgba(159, 100, 197, 0.8);
  --ncui-point-download1: #D1B4CF;
  --ncui-point-download2: #B09FBB;
  --ncui-footer-bg: linear-gradient(0deg, #0E0816, #0E0816), #101017;
  --ncui-footer-select-border: #332E3A;
  --ncui-footer-select-bg: #211C29;
}

html[data-service=tl].dark-mode {
  --ncui-point: #6C3C8B;
  --ncui-point040: rgba(108, 60, 139, 0.4);
  --ncui-point-hover: #61367D;
  --ncui-point-text: #CA87F6;
  --ncui-point-text080: rgba(202, 135, 246, 0.55);
  --ncui-point-download1: #6A4C68;
  --ncui-point-download2: #432D51;
  --ncui-footer-bg: linear-gradient(0deg, #0E0816, #0E0816), #101017;
  --ncui-footer-select-border: #332E3A;
  --ncui-footer-select-bg: #211C29;
}

html[data-service=lovebeat] {
  --ncui-point: #EB4196;
  --ncui-point040: rgba(235, 65, 150, 0.4);
  --ncui-point-hover: #D43A87;
  --ncui-point-text: #EB4196;
  --ncui-point-text080: rgba(235, 65, 150, 0.8);
  --ncui-point-download1: #F07DB7;
  --ncui-point-download2: #C39FF5;
  --ncui-point-download3: #95B7F9;
}

html[data-service=lovebeat].dark-mode {
  --ncui-point: #D73A88;
  --ncui-point040: rgba(215, 58, 136, 0.4);
  --ncui-point-hover: #C1347A;
  --ncui-point-text: #AB316E;
  --ncui-point-text080: rgba(171, 49, 110, 0.55);
  --ncui-point-download1: #844b67;
  --ncui-point-download2: #6d5b86;
  --ncui-point-download3: #576788;
}

html[data-service=battlecrush] {
  --ncui-point: #4B97CB;
  --ncui-point040: rgba(75, 151, 203, 0.4);
  --ncui-point-hover: #4388B7;
  --ncui-point-text: #4B97CB;
  --ncui-point-text080: rgba(75, 151, 203, 0.8);
}

html[data-service=battlecrush].dark-mode {
  --ncui-point: #497FA5;
  --ncui-point040: rgba(73, 127, 165, 0.4);
  --ncui-point-hover: #3B6785;
  --ncui-point-text: #96D3FF;
  --ncui-point-text080: rgba(150, 211, 255, 0.55);
}

html[data-service=hoyeon] {
  --ncui-point: #609BEB;
  --ncui-point040: rgba(96, 155, 235, 0.4);
  --ncui-point-hover: #568CD4;
  --ncui-point-text: #609BEB;
  --ncui-point-text080: rgba(96, 155, 235, 0.8);
}

html[data-service=hoyeon].dark-mode {
  --ncui-point: #4078C2;
  --ncui-point040: rgba(64, 120, 194, 0.4);
  --ncui-point-hover: #3A6CAF;
  --ncui-point-text: #81B7FF;
  --ncui-point-text080: rgba(129, 183, 255, 0.55);
}

html[data-service=puzzup] {
  --ncui-point: #FCA813;
  --ncui-point040: rgba(252, 168, 19, 0.4);
  --ncui-point-hover: #E39711;
  --ncui-point-text: #FCA813;
  --ncui-point-text080: rgba(252, 168, 19, 0.8);
}

html[data-service=puzzup].dark-mode {
  --ncui-point: #E29B00;
  --ncui-point040: rgba(226, 155, 0, 0.4);
  --ncui-point-hover: #CB8D00;
  --ncui-point-text: #FEAE00;
  --ncui-point-text080: rgba(254, 174, 0, 0.55);
}

html[data-service=journey] {
  --ncui-point: #AE5244;
  --ncui-point040: rgba(174, 82, 68, 0.4);
  --ncui-point-hover: #9D4A3D;
  --ncui-point-text: #AE5244;
  --ncui-point-text080: rgba(174, 82, 68, 0.8);
  --ncui-footer-bg: #101415;
  --ncui-footer-select-border: #272f31;
  --ncui-footer-select-bg: #1a2223;
}

html[data-service=journey].dark-mode {
  --ncui-point: #883C30;
  --ncui-point040: rgba(136, 60, 48, 0.4);
  --ncui-point-hover: #7A362B;
  --ncui-point-text: #C65948;
  --ncui-point-text080: rgba(198, 89, 72, 0.55);
  --ncui-footer-bg: #101415;
  --ncui-footer-select-border: #272f31;
  --ncui-footer-select-bg: #1a2223;
}

html[data-service=bnsneo] {
  --ncui-point: #0075B7;
  --ncui-point040: rgba(0, 117, 183, 0.4);
  --ncui-point-hover: #0069A5;
  --ncui-point-text: #0075B7;
  --ncui-point-text080: rgba(0, 117, 183, 0.8);
  --ncui-point-download1: #82C1CF;
  --ncui-point-download2: #7AB0D8;
  --ncui-point-download3: #86A8E1;
  --ncui-point-download4: #949CDF;
}

html[data-service=bnsneo].dark-mode {
  --ncui-point: #0679CE;
  --ncui-point040: rgba(6, 121, 206, 0.4);
  --ncui-point-hover: #056DB9;
  --ncui-point-text: #3D98DD;
  --ncui-point-text080: rgba(61, 152, 221, 0.55);
  --ncui-point-download1: #507881;
  --ncui-point-download2: #2E638B;
  --ncui-point-download3: #46669A;
  --ncui-point-download4: #5D6399;
}