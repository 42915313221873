@mixin color-theme ($services, $light-theme, $dark-theme) {
  @if $services {
    @if $service == 'lineage2m' {
      html[data-service^='#{$service}'] {
        @each $k, $v in $light-theme {
          #{"--ncui-" + $k}: #{$v};
        }
      }
      html[data-service^='#{$service}'].dark-mode {
        @each $k, $v in $dark-theme {
          #{"--ncui-" + $k}: #{$v};
        }
      }
    }
    @else {
      html[data-service='#{$service}'] {
        @each $k, $v in $light-theme {
          #{"--ncui-" + $k}: #{$v};
        }
      }
      html[data-service='#{$service}'].dark-mode {
        @each $k, $v in $dark-theme {
          #{"--ncui-" + $k}: #{$v};
        }
      }
    }
  }

  @else {
    :root{
      @each $k, $v in $light-theme{
        #{"--ncui-" + $k}: #{$v};
      }
    }
    html.dark-mode {
      @each $k, $v in $dark-theme{
        #{"--ncui-" + $k}: #{$v};
      }
    }
  }

}



