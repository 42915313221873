@charset "UTF-8";
@import "../utils/theme-mixin";
/////////////////////////////////////////
/*
1.
$light-앨리어스네임 : 컬러값
$dark-앨리어스네임 : 컬러값
쌍으로 컬러별 변수를 정의한다. #hex의 경우 끝에 -hex 를 붙힌다.

2. $light-theme, $dark-theme 리스트에 1.을 추가한다
*/
/////////////////////////////////////////
/* 2. Gray, 3. Text, Button(=Gray) --> RGBA, RGB to Hex */

$light-gray002 : rgba(#222222, 0.02);
$dark-gray002 : rgba(#FFFFFF, 0.02);
$light-gray002-hex: #FBFBFB;
$dark-gray002-hex: #1B1B1B;

$light-gray004 : rgba(#222222, 0.04);
$dark-gray004 : rgba(#FFFFFF, 0.04);
$light-gray004-hex: #F6F6F6;
$dark-gray004-hex: #202020;

$light-gray008 : rgba(#222222, 0.08);
$dark-gray008 : rgba(#FFFFFF, 0.08);
$light-gray008-hex: #EEEEEE;
$dark-gray008-hex: #292929;

$light-gray010 : rgba(#222222, 0.1);
$dark-gray010 : rgba(#FFFFFF, 0.1);
$light-gray010-hex: #E8E8E8;
$dark-gray010-hex: #2E2E2E;

$light-gray012 : rgba(#222222, 0.12);
$dark-gray012 : rgba(#FFFFFF, 0.12);
$light-gray012-hex: #E4E4E4;
$dark-gray012-hex: #333333;

$light-gray016 : rgba(#222222, 0.16);
$dark-gray016 : rgba(#FFFFFF, 0.16);
$light-gray016-hex: #DBDBDB;
$dark-gray016-hex: #3C3C3C;

$light-gray020 : rgba(#222222, 0.2);
$dark-gray020 : rgba(#FFFFFF, 0.2);
$light-gray020-hex: #D3D3D3;
$dark-gray020-hex: #454545;

$light-gray040 : rgba(#222222, 0.4);
$dark-gray040 : rgba(#FFFFFF, 0.4);
$light-gray040-hex: #A7A7A7;
$dark-gray040-hex: #A7A7A7;

$light-black045: rgba(#000000, 0.45);
$dark-black045: rgba(#FFFFFF, 0.38);

$light-black068 : rgba(#000000, 0.68);
$dark-black068: rgba(#FFFFFF, 0.6);

$light-black093: rgba(#000000, 0.93);
$dark-black093: rgba(#FFFFFF, 0.83);

$light-black100: #000000;
$dark-black100: #FFFFFF;

$light-white100: #FFFFFF;
$dark-white100: #000000;

$light-ui-white: #FFFFFF;
$dark-ui-white: #FFFFFF;

$light-ui-black: #000000;
$dark-ui-black: #000000;

/* 1. BG,  4. UI Text  --> only RGB to Hex  */

$light-bg: #FFFFFF;
$dark-bg: #171717;

$light-error: #DC4141;
$dark-error: #B94444;

$light-bookmark: #FFD042;
$dark-bookmark: #CCA634;

$light-success: #1EB22E;
$dark-success: #2F8738;

$light-button-normal: #333333;
$dark-button-normal: #D7D7D7;

$light-button-hover: #000000;
$dark-button-hover: #FFFFFF;

$light-linebutton-normal: rgba(#222222, 0.12);
$dark-linebutton-normal: rgba(#FFFFFF, 0.12);


/////////////////////////////////////////
/* light-mode, dark-mode color list  */

$light-theme: (
        gray002: $light-gray002,
        gray002-hex: $light-gray002-hex,
        gray004: $light-gray004,
        gray004-hex: $light-gray004-hex,
        gray008: $light-gray008,
        gray008-hex: $light-gray008-hex,
        gray010: $light-gray010,
        gray010-hex: $light-gray010-hex,
        gray012: $light-gray012,
        gray012-hex: $light-gray012-hex,
        gray016: $light-gray016,
        gray016-hex: $light-gray016-hex,
        gray020: $light-gray020,
        gray020-hex: $light-gray020-hex,
        gray040: $light-gray040,
        gray040-hex: $light-gray040-hex,
        black045 : $light-black045,
        black068 : $light-black068,
        black093 : $light-black093,
        black100 : $light-black100,
        white100 : $light-white100,
        ui-white: $light-ui-white,
        ui-black: $light-ui-black,
        bg: $light-bg,
        error: $light-error,
        bookmark: $light-bookmark,
        sucess: $light-success,
        button-normal: $light-button-normal,
        button-hover: $light-button-hover,
        linebutton-noraml: $light-linebutton-normal,
);

$dark-theme: (
        gray002: $dark-gray002,
        gray002-hex: $dark-gray002-hex,
        gray004: $dark-gray004,
        gray004-hex: $dark-gray004-hex,
        gray008: $dark-gray008,
        gray008-hex: $dark-gray008-hex,
        gray010: $dark-gray010,
        gray010-hex: $dark-gray010-hex,
        gray012: $dark-gray012,
        gray012-hex: $dark-gray012-hex,
        gray016: $dark-gray016,
        gray016-hex: $dark-gray016-hex,
        gray020: $dark-gray020,
        gray020-hex: $dark-gray020-hex,
        gray040: $dark-gray040,
        gray040-hex: $dark-gray040-hex,
        black045 : $dark-black045,
        black068 : $dark-black068,
        black093 : $dark-black093,
        black100 : $dark-black100,
        white100 : $dark-white100,
        ui-white: $dark-ui-white,
        ui-black: $dark-ui-black,
        bg: $dark-bg,
        error: $dark-error,
        bookmark: $dark-bookmark,
        sucess: $dark-success,
        button-normal: $dark-button-normal,
        button-hover: $dark-button-hover,
        linebutton-noraml: $dark-linebutton-normal,
);


/////////////////////////////////////////
/* color mixin import */
@include color-theme(false, $light-theme, $dark-theme);